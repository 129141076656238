import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto key format`}</strong>{` -- reformat a public or private key`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto key format <key-file> [--out=<file>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto key format`}</strong>{` prints or writes the key in
a different format.`}</p>
    <p>{`By default PEM formatted keys will be converted to DER with the following rules:`}</p>
    <ul>
      <li parentName="ul">{`ECDSA, RSA, AND Ed25519 public keys will use the DER-encoded PKIX format.`}</li>
      <li parentName="ul">{`ECDSA, AND RSA private keys will use the ASN.1, DER format.`}</li>
      <li parentName="ul">{`Ed25519 private keys will use the DER-encoded PKCS8 encoded form.`}</li>
    </ul>
    <p>{`And DER encoded keys will be converted to PEM with the following rules:`}</p>
    <ul>
      <li parentName="ul">{`ECDSA, RSA, AND Ed25519 public keys will use the PEM-encoded PKIX format.`}</li>
      <li parentName="ul">{`ECDSA private keys will use the PEM-encoded format defined in RFC 5915 and
SEC1.`}</li>
      <li parentName="ul">{`RSA private keys will use the PEM-encoded PKCS#1 format.`}</li>
      <li parentName="ul">{`Ed25519 private keys will use the PEM-encoded PKCS#8 format.`}</li>
    </ul>
    <p>{`The flags `}<strong parentName="p">{`--pkcs8`}</strong>{`, `}<strong parentName="p">{`--pem`}</strong>{`, `}<strong parentName="p">{`--der`}</strong>{`, `}<strong parentName="p">{`--ssh`}</strong>{`, and `}<strong parentName="p">{`--jwk`}</strong>{` can be use
to change the previous defaults. For example we can use `}<strong parentName="p">{`--pkcs8`}</strong>{` to save a
PKCS#1 RSA key to the PKCS#8 form. Or we can combine `}<strong parentName="p">{`--pem`}</strong>{` and `}<strong parentName="p">{`--pkcs8`}</strong>{`
to convert to PKCS#8 a PEM file.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`key-file`}</inlineCode>{`
Path to a file with a public or private key, or the public key of an
X.509 certificate.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--pkcs8`}</strong>{`
Convert RSA and ECDSA private keys to PKCS#8 PEM/DER format.`}</p>
    <p><strong parentName="p">{`--pem`}</strong>{`
Uses PEM as the result encoding format. If neither `}<strong parentName="p">{`--pem`}</strong>{` nor `}<strong parentName="p">{`--der`}</strong>{` nor
`}<strong parentName="p">{`--ssh`}</strong>{` nor `}<strong parentName="p">{`--jwk`}</strong>{` are set it will always switch to the DER format.`}</p>
    <p><strong parentName="p">{`--der`}</strong>{`
Uses DER as the result enconfig format. If neither `}<strong parentName="p">{`--pem`}</strong>{` nor `}<strong parentName="p">{`--der`}</strong>{` nor
`}<strong parentName="p">{`--ssh`}</strong>{` nor `}<strong parentName="p">{`--jwk`}</strong>{` are set it will always switch to the PEM format.`}</p>
    <p><strong parentName="p">{`--ssh`}</strong>{`
Uses OpenSSH as the result encoding format.`}</p>
    <p><strong parentName="p">{`--jwk`}</strong>{`
Uses JSON Web Key as the result encoding format.`}</p>
    <p><strong parentName="p">{`--out`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Path to write the reformatted result.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Location of file containing passphrase to decrypt private key.`}</p>
    <p><strong parentName="p">{`--no-password`}</strong>{`
Do not ask for a password to encrypt a private key with PEM format. Sensitive
key material will be written to disk unencrypted. This is not recommended.
Requires `}<strong parentName="p">{`--insecure`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--insecure`}</strong></p>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <h2>{`Exit codes`}</h2>
    <p>{`This command returns 0 on success and >0 if any error occurs.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Convert a PEM file to DER:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key format key.pem
`}</code></pre>
    <p>{`Convert DER file to PEM:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key format key.der
`}</code></pre>
    <p>{`Convert a PEM file to OpenSSH:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key format --ssh key.pem
`}</code></pre>
    <p>{`Convert a PEM file to JWK:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key format --jwk key.pem
`}</code></pre>
    <p>{`Convert PEM file to DER and write to disk:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key format key.pem --out key.der
`}</code></pre>
    <p>{`Convert a PKCS#1 RSA private key to PKCS#8 using the PEM format:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key format --pem --pkcs8 rsa.pem --out rsa-pkcs8.pem
`}</code></pre>
    <p>{`Convert PKCS#8 RSA private key to the PKCS#1 format:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key format --pem rsa-pkcs8.pem --out rsa.pem
`}</code></pre>
    <p>{`Convert an ASN.1 DER format to the PEM-encoded PKCS#8 format:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key format --pkcs8 key.der --out key-pkcs8.der
`}</code></pre>
    <p>{`Convert an ASN.1 DER format to the DER-encoded PKCS#8 format:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto key format --der --pkcs8 key.der --out key-pkcs8.der
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      